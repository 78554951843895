<template>
  <div>
    <el-button style="width: 200px;margin-left: 45px" type="primary" @click="run()">开端</el-button>

  </div>
  <t1 style="margin-top: 80px" v-show="host.length > 1">开端结果:http://{{host}}:{{port}}</t1>

</template>

<script>
export default {
  name: "NfBoard",
  data(){
    return{
      host:"",
      port:"",
    }
  },
  methods:{
    run(){
      this.$axios.get( 'http://127.0.0.1:8082/global/openNf').then(res => {

        if(res.data.code == 1){
          let arg = res.data.data.split(":");
          this.host = arg[0];
          this.port = arg[1];
        }

      }).catch(()=>{
        this.$message.error("未知错误!");
      });
    },
    heart(){
      if(this.host.length < 1){
        return
      }
      this.$axios.get( 'http://127.0.0.1:8082/global/heart',{params:{host:this.host,port:this.port}}).then(res => {
        console.log(res)
      }).catch(()=>{
        this.$message.error("未知错误!");
      });
    }
  },
  mounted() {
    // 设置一个每2000毫秒执行一次的定时器
    setInterval(this.heart, 5000);
  }
}
</script>

<style scoped>

</style>