import { createApp } from 'vue'
import App from './App.vue'
import ElementPlus from 'element-plus'
import 'element-plus/dist/index.css'
import axios from "axios";
import router from './routers/router'
const app = createApp(App)
axios.defaults.withCredentials = true

app.use(router)
app.use(ElementPlus)
app.config.globalProperties.$axios = axios
app.config.globalProperties.$axios.defaults.withCredentials = true

app.mount('#app')
