<template>
  <el-tabs v-model="activeName">
    <el-tab-pane label="用户管理" name="first">


      <el-button
          style="width: 20%;"
          type="warning"
          @click="quickResetHwnd()"
      >快速解绑</el-button>
      <el-button
          style="width: 20%;"
          type="warning"
          @click="userManager.searchCondition.show = true"
      >查找用户</el-button>
      <el-button
          style="width: 20%;"
          type="warning"
          @click="userManager.insertCondition.show = true"
      >添加用户</el-button>
      <el-table
          :data="userManager.users"
          >
        <el-table-column
            label="用户名"
            prop="username"
            width="180px">
        </el-table-column>
        <el-table-column
            label="残卡日期"
            width="250px">
          <template v-slot="scope">
            <i class="el-icon-time"></i>
            <el-date-picker
                v-model="scope.row.model1Time"
                value-format="x"
                type="datetime"
                placeholder="选择日期时间">
            </el-date-picker>
          </template>
        </el-table-column>
        <el-table-column
            label="正版卡日期"
            width="250px">
          <template v-slot="scope">
            <i class="el-icon-time"></i>
            <el-date-picker
                v-model="scope.row.model2Time"
                type="datetime"
                value-format="x"
                placeholder="选择日期时间">
            </el-date-picker>
          </template>
        </el-table-column>
        <el-table-column
            label="状态"
            width="180">
          <template v-slot="scope">
            {{userManager.statusDefine[scope.row.status]}}
          </template>
        </el-table-column>
        <el-table-column   width="90">
          <template v-slot="scope">
            <el-button
                type="info"
                size="mini"
                @click="resetHwnd(scope.row.username)"
            >解绑</el-button>
          </template>
        </el-table-column>
        <el-table-column   width="90">
          <template v-slot="scope">
            <el-button
                type="primary"
                size="mini"
                @click="saveUser(scope.row)"
            >保存</el-button>
          </template>
        </el-table-column>
        <el-table-column   width="90">
          <template v-slot="scope">
            <el-button
                type="danger"
                size="mini"
                @click="ban(scope.row)"
            >封禁</el-button>
          </template>
        </el-table-column>
        <el-table-column   >
          <template v-slot="scope">
            <el-button
                type="success"
                size="mini"
                @click="resetBan(scope.row)"
            >解禁</el-button>
          </template>
        </el-table-column>
      </el-table>

      <el-dialog
          v-model="userManager.searchCondition.show"
          title="搜索"
          width="500"
      >
        <el-input
            v-model="userManager.searchCondition.username"
            style="max-width: 600px"
            placeholder="请输入用户名(模糊搜索)"
            class="input-with-select"
        >
          <template #prepend>
            用户名
          </template>
        </el-input>
        <template #footer>
          <div class="dialog-footer">
            <el-button @click="userManager.searchCondition.show = false">取消</el-button>
            <el-button type="primary" @click="queryUserInfoByConditon();userManager.searchCondition.show = false">
              搜索
            </el-button>
          </div>
        </template>
      </el-dialog>


      <el-dialog
          v-model="userManager.insertCondition.show"
          title="添加新用户"
          width="500"
      >
        <div>
          用户名:
          <el-input
              v-model="userManager.insertUser.username"
              style="max-width: 200px"
              placeholder="请输入用户名"
              class="input-with-select"
          >
          </el-input>
        </div>
        <div style="margin-top: 10px">
          残卡日期:
          <el-date-picker
              v-model="userManager.insertUser.model1Time"
              value-format="x"

              type="datetime"
              placeholder="选择日期时间">
            <template #prepend>

            </template>
          </el-date-picker>
        </div>
        <div style="margin-top: 10px">
          正常日期:
          <el-date-picker
              v-model="userManager.insertUser.model2Time"
              value-format="x"

              type="datetime"
              placeholder="选择日期时间">
            <template #prepend>

            </template>
          </el-date-picker>
        </div>
        <template #footer>
          <div class="dialog-footer">
            <el-button @click="userManager.insertCondition.show = false">取消</el-button>
            <el-button type="primary" @click="insertUser();userManager.insertCondition.show = false">
              添加
            </el-button>
          </div>
        </template>
      </el-dialog>

      <el-pagination
          @current-change="handleUserCurrentChange"
          :page-size="50"
          :pager-count="11"
          layout="prev, pager, next"
          :total="10000"
      />
    </el-tab-pane>
    <el-tab-pane label="订单管理" name="third">
      <el-button
          style="width: 50%;"
          type="warning"
          @click="orderManager.searchCondition.show = true"
      >过滤</el-button>
      <el-table
          :data="orderManager.orders"
      >
        <el-table-column
            label="订单号"
            prop="no"
            width="180px">
        </el-table-column>
        <el-table-column
            label="用户名"
            prop="username"
            width="180px">
        </el-table-column>
        <el-table-column
            label="状态"
            width="180">
          <template v-slot="scope">
            {{userManager.statusDefine[scope.row.status]}}
          </template>
        </el-table-column>
        <el-table-column   width="90">
          <template v-slot="scope">
            <el-button
                type="danger"
                size="mini"
                @click="scope.row.status = -1;saveOrder(scope.row)"
            >封禁</el-button>
          </template>
        </el-table-column>
        <el-table-column   >
          <template v-slot="scope">
            <el-button
                type="success"
                size="mini"
                @click="scope.row.status = 0;saveOrder(scope.row)"
            >解禁</el-button>
          </template>
        </el-table-column>
      </el-table>

      <el-pagination
          @current-change="handleOrderCurrentChange"
          :page-size="50"
          :pager-count="11"
          layout="prev, pager, next"
          :total="10000"
      />
      <el-dialog
          v-model="orderManager.searchCondition.show"
          title="搜索"
          width="500"
      >
        <el-input
            v-model="orderManager.searchCondition.no"
            style="max-width: 600px"
            placeholder="请输入订单号(模糊搜索)"
            class="input-with-select"
        >
          <template #prepend>
            订单号
          </template>
        </el-input>
        <el-input
            v-model="orderManager.searchCondition.username"
            style="max-width: 600px"
            placeholder="请输入用户名(模糊搜索)"
            class="input-with-select"
        >
          <template #prepend>
            用户名
          </template>
        </el-input>
        <template #footer>
          <div class="dialog-footer">
            <el-button @click="orderManager.searchCondition.show = false">取消</el-button>
            <el-button type="primary" @click="queryOrderInfoByConditon();orderManager.searchCondition.show = false">
              搜索
            </el-button>
          </div>
        </template>
      </el-dialog>
    </el-tab-pane>
    <el-tab-pane label="生成卡密" name="second">
      <el-input
          v-model="commonManager.makeResult"
          :rows="9"
          type="textarea"
      />

      <el-button :disabled="commonManager.running" type="primary" style="margin-top:30px;margin-left: 100px;width: 200px" @click="makeCard()">
        生成注册码
      </el-button>


      <el-button :disabled="commonManager.running" type="primary" style="margin-top:30px;margin-left: 100px;width: 200px" @click="nfManager.nfCardCreateWindowShow = true">
        生成NF卡
      </el-button>

      <el-dialog
          v-model="nfManager.nfCardCreateWindowShow"
          title="生成卡密"
          width="500"
      >
        <div>
          <div>
            类型:
            <el-select
                v-model="nfManager.nfCardCreateInfo.type"
                placeholder="类型"
                style="width: 200px"
            >
              <el-option
                  v-for="item in nfManager.nfCardType"
                  :key="item.value"
                  :label="item.label"
                  :value="item.value"
              />
            </el-select>
          </div>
          <div>
          时间:
          <el-input
              v-model="nfManager.nfCardCreateInfo.day"
              style="max-width: 200px"
              placeholder="请输入合法天数"
              class="input-with-select"
          >
          </el-input>
          </div>
          <div>
            数量:
          <el-input
              v-model="nfManager.nfCardCreateInfo.num"
              style="max-width: 200px"
              placeholder="生成数量"
              class="input-with-select"
          >
          </el-input>
          </div>
        </div>
        <div>
          <el-button style="margin-top: 10px;width: 230px;height: 30px" @click="createNfCard()">生成</el-button>
        </div>
      </el-dialog>

    </el-tab-pane>
    <el-tab-pane label="UI配置" name="fourth">
      <el-form
          style="max-width: 600px"
          status-icon
          label-width="auto"
      >
        <el-form-item label="背景图片">
          <el-input v-model="uiManager.backgroundUrl" />
        </el-form-item>
        <el-form-item label="公告">
          <el-input
              v-model="uiManager.advText"
              :rows="9"
              type="textarea"
          />
        </el-form-item>
        <el-form-item label="公告字体颜色">
          <el-color-picker v-model="uiManager.advColor" size="large" />
        </el-form-item>
        <el-form-item>
          <el-button type="primary" style="margin-left: 100px;width: 200px" @click="saveUI(this.uiManager)">
            保存
          </el-button>
        </el-form-item>
      </el-form>
    </el-tab-pane>
    <el-tab-pane label="解绑理由" name="six">
      <el-table
          :data="reasonManager.reasons"
      >
        <el-table-column
            label="用户名"
            prop="username"
            width="180px">
        </el-table-column>
        <el-table-column
            label="类型"
            width="180">
          <template v-slot="scope">
            {{scope.row.no == null ?'残卡':'正常卡'}}
          </template>
        </el-table-column>
        <el-table-column
            label="原因"
            prop="reason"
            width="180px">
        </el-table-column>
        <el-table-column>
          <template v-slot="scope">
            <i class="el-icon-time"></i>
            <el-date-picker
                v-model="scope.row.time"
                value-format="x"
                type="datetime"
                disabled
                placeholder="该日期未初始化">
            </el-date-picker>
          </template>
        </el-table-column>
      </el-table>
      <el-pagination
          @current-change="handleReasonCurrentChange"
          :page-size="50"
          :pager-count="11"
          layout="prev, pager, next"
          :total="10000"
      />
    </el-tab-pane>
    <el-tab-pane label="代理管理" name="seven">
      <el-form
          style="max-width: 600px"
          status-icon
          label-width="auto"
      >
        <el-form-item label="公告">
          <el-input
              v-model="pmManager.advText"
              :rows="3"
              type="textarea"
          />
        </el-form-item>
        <el-form-item>
          <el-button type="primary" style="margin-left: 100px;width: 200px" @click="savePmAdv(this.pmManager.advText)">
            保存
          </el-button>
        </el-form-item>
      </el-form>

      <el-table
          :data="this.pmManager.cardTypes"
          border
          style="width: 100%">
        <el-table-column
            fixed
            prop="type"
            label="标识"
           >
        </el-table-column>
        <el-table-column
            label="排序权重"
        >
          <template v-slot="scope">
            <el-input v-model="scope.row.level"></el-input>
          </template>
        </el-table-column>
        <el-table-column
            label="名称"
        >
          <template v-slot="scope">
            <el-input v-model="scope.row.title"></el-input>
          </template>
        </el-table-column>
        <el-table-column
            prop="number"
            label="剩余"
        >
        </el-table-column>
        <el-table-column
            label="价钱"
        >
          <template v-slot="scope">
            <el-input v-model="scope.row.point"></el-input>
          </template>
        </el-table-column>
        <el-table-column
            label="u价钱"
        >
          <template v-slot="scope">
            <el-input v-model="scope.row.upoint"></el-input>
          </template>
        </el-table-column>
        <el-table-column
            label="组"

        >
          <template v-slot="scope">
            <el-input v-model="scope.row.organize"></el-input>
          </template>
        </el-table-column>

        <el-table-column
            label="类型"

        >
          <template v-slot="scope">
            <el-input v-model="scope.row.parent"></el-input>
          </template>
        </el-table-column>
        <el-table-column
            fixed="right"
            label="操作"
            >
          <template v-slot="scope">
            <el-button @click="saveCardTypeInfo(scope.row)" type="text" size="small">保存</el-button>
            <el-button @click="deleteCardType(scope.row.type)" type="text" size="small">删除</el-button>
          </template>
        </el-table-column>
      </el-table>
      <el-button type="primary" style="margin-top:30px;margin-left: 100px;width: 200px" @click="insertCardType()">
        插入卡类
      </el-button>


      <div style="width: 500px" >
        <el-form
            style="max-width: 600px"
            status-icon
            label-width="auto"
        >
          <el-form-item  label="卡类" >
            <el-select v-model="pmManager.createInfo.type" placeholder="请选择卡类">
              <el-option v-for="item in this.pmManager.cardTypes" :key="item.id" :label="item.title" :value="item.type"></el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="卡密" >
            <el-input
                v-model="this.pmManager.createInfo.cards"
                :rows="10"
                type="textarea"
            />
          </el-form-item>

          <el-form-item>
            <el-button style="width: 200px;margin-left: 45px" type="primary" @click="batchInsertCard(this.pmManager.createInfo.cards)">导入卡密</el-button>
          </el-form-item>
        </el-form>

      </div>



    </el-tab-pane>

    <el-tab-pane label="代理权限" name="eight">
      <el-button
          style="width: 20%;"
          type="warning"
          @click="pmManager.insertCondition.show = true"
      >添加代理</el-button>

      <el-table
          :data="pmManager.pms"
      >
        <el-table-column
            label="账号"
            prop="account"
            />
          <el-table-column
              label="点数"

              >
            <template v-slot="scope">
              <el-input v-model="scope.row.point"></el-input>
            </template>
          </el-table-column>
        <el-table-column
            label="u点数"

        >
          <template v-slot="scope">
            <el-input v-model="scope.row.upoint"></el-input>
          </template>
        </el-table-column>
        <el-table-column
                label="充值最低额度"

                >
              <template v-slot="scope">
                <el-input v-model="scope.row.needMoney"></el-input>
              </template>
            </el-table-column>
        <el-table-column
            label="组"

        >
          <template v-slot="scope">
            <el-select
                v-model="scope.row.organizesArray"
                multiple
                collapse-tags
                placeholder="请选择">
              <el-option
                  v-for="item in this.getGroupArray()"
                  :key="item"
                  :label="item"
                  :value="item">
              </el-option>
            </el-select>
          </template>
        </el-table-column>
        <el-table-column   >

          <template v-slot="scope">
            <el-button
                type="primary"
                size="mini"
                @click="savePm(scope.row)"
            >保存</el-button>
            <el-button
                type="warning"
                size="mini"
                @click="queryCardHistoryBeforeTwoMonth(scope.row.account)"
            >记录</el-button>
            <el-button
                type="warning"
                size="mini"
                @click="getWjmPrivatePriceByUser(scope.row.id)"
            >私价</el-button>
            <el-button
                type="danger"
                size="mini"
                @click="deleteBoss(scope.row.id)"
            >删除</el-button>
          </template>
        </el-table-column>
      </el-table>
      <el-dialog
          v-model="pmManager.insertCondition.show"
          title="添加新代理"
          width="500"
      >
        <div>
          账号:
          <el-input
              v-model="pmManager.insertUser.account"
              style="max-width: 200px"
              placeholder="请输入账号"
              class="input-with-select"
          >
          </el-input>
          <el-input
              v-model="pmManager.insertUser.password"
              style="max-width: 200px"
              placeholder="请输入密码"
              class="input-with-select"
          >
          </el-input>
        </div>
        <template #footer>
          <div class="dialog-footer">
            <el-button @click="pmManager.insertCondition.show = false">取消</el-button>
            <el-button type="primary" @click="insertPm();pmManager.insertCondition.show = false">
              添加
            </el-button>
          </div>
        </template>
      </el-dialog>
      <el-dialog
          v-model="pmManager.cardHistoryShow"
          title="查看"
          width="500"
      >
        <div>
          <el-table
              :data="pmManager.cardHistory"
          >
            <el-table-column
                label="卡密"
                prop="card"
            />
            <el-table-column
                label="类型"
                prop="type"
            />
            <el-table-column
                label="日期"
                prop="time"
            />
          </el-table>
        </div>
        <template #footer>
          <div class="dialog-footer">
            <el-button @click="pmManager.cardHistoryShow = false;pmManager.cardHistory=[];">确定</el-button>
          </div>
        </template>
      </el-dialog>

      <el-dialog
          v-model="pmManager.cardPrivatePriceShow"
          title="查看"
          width="500"
      >
        <div>
          <el-table
              :data="pmManager.cardPrivatePrice"
          >
            <el-table-column
                label="卡类"
            >
              <template v-slot="scope">
                <p>{{this.getCardTitleById(scope.row.cardType)}}</p>
              </template>
            </el-table-column>
            <el-table-column
                label="价格"
            >
              <template v-slot="scope">
                <el-input v-model="scope.row.point"></el-input>
              </template>
            </el-table-column>>
            <el-table-column   >
              <template v-slot="scope">
                <el-button
                    type="primary"
                    size="mini"
                    @click="updateWjmPrivatePrice(scope.row)"
                >保存</el-button>
                <el-button
                    type="warning"
                    size="mini"
                    @click="deleteWjmPrivatePrice(scope.row.id)"
                >删除</el-button>
              </template>
            </el-table-column>
          </el-table>
        </div>
        <template #footer>
          <div class="dialog-footer">
            <el-select v-model="pmManager.createPrivatePriceInfo.cardType" placeholder="请选择卡类">
              <el-option v-for="item in this.pmManager.cardTypes" :key="item.id" :label="item.title" :value="item.type"></el-option>
            </el-select>
            <el-input v-model="pmManager.createPrivatePriceInfo.point">
            </el-input>
            <el-button @click="updateWjmPrivatePrice(pmManager.createPrivatePriceInfo)">插入</el-button>
            <el-button @click="pmManager.cardPrivatePriceShow = false;pmManager.cardPrivatePrice=[];">关闭</el-button>
          </div>
        </template>
      </el-dialog>
    </el-tab-pane>


    <el-tab-pane label="卡密管理" name="night">
      <el-collapse v-model="pmManager.cardActiveShowBoard" accordion>
        <el-collapse-item title="卡密查询" name="1">
          <el-button
              style="width: 20%;"
              type="warning"
              @click="flushCardInfo()"
          >刷新当前页信息</el-button>

          <el-select v-model="pmManager.queryCardInfoConditionRequest.type" placeholder="请选择卡类">
            <el-option v-for="item in this.pmManager.cardTypes" :key="item.id" :label="item.title" :value="item.type"></el-option>
          </el-select>
          <el-select v-model="pmManager.queryCardInfoConditionRequest.status" placeholder="查询状态">
            <el-option  label="未使用" :value="0"></el-option>
            <el-option  label="使用" :value="1"></el-option>
          </el-select>
          <el-input
              v-model="pmManager.queryCardInfoConditionRequest.text"
              placeholder="请输入卡密(模糊搜索)"
              class="input-with-select"
          >
          </el-input>
          <el-table
              :data="pmManager.cards"
          >
            <el-table-column
                label="卡号"
                prop="card"
            >
            </el-table-column>
            <el-table-column
                label="注册码"
                prop="registerCode"
            >
            </el-table-column>
            <el-table-column
                label="类型"
                prop="type"
            >
            </el-table-column>
            <el-table-column
                label="使用者"
                prop="account"
            >
            </el-table-column>
            <el-table-column
                label="使用日期"
                prop="time"
            >
            </el-table-column>
            <el-table-column>
              <template v-slot="scope">
                <el-button
                    type="danger"
                    size="mini"
                    @click="deleteCard(scope.row.id)"
                >删除</el-button>
              </template>
            </el-table-column>
          </el-table>


          <el-pagination
              @current-change="handleCardCurrengChange"
              :page-size="50"
              :pager-count="11"
              layout="prev, pager, next"
              :total="10000"
          />
          <el-dialog
              v-model="orderManager.searchCondition.show"
              title="搜索"
              width="500"
          >
            <el-input
                v-model="orderManager.searchCondition.no"
                style="max-width: 600px"
                placeholder="请输入订单号(模糊搜索)"
                class="input-with-select"
            >
              <template #prepend>
                订单号
              </template>
            </el-input>
            <el-input
                v-model="orderManager.searchCondition.username"
                style="max-width: 600px"
                placeholder="请输入用户名(模糊搜索)"
                class="input-with-select"
            >
              <template #prepend>
                用户名
              </template>
            </el-input>
            <template #footer>
              <div class="dialog-footer">
                <el-button @click="orderManager.searchCondition.show = false">取消</el-button>
                <el-button type="primary" @click="queryOrderInfoByConditon();orderManager.searchCondition.show = false">
                  搜索
                </el-button>
              </div>
            </template>
          </el-dialog>
        </el-collapse-item>

        <el-collapse-item title="算账" name="2">
          <el-date-picker
              v-model="pmManager.calBeginTime"
              value-format="x"
              type="datetime"
              placeholder="选择开始时间">
          </el-date-picker>
          <el-date-picker
              v-model="pmManager.calEndTime"
              value-format="x"
              type="datetime"
              placeholder="选择结束时间">
          </el-date-picker>

          <el-checkbox-group v-model="pmManager.calCardTypes">
            <el-checkbox v-for="item in pmManager.cardTypes" :key="item.id" :label="item.title" :value="item.type">{{item.title}}</el-checkbox>
          </el-checkbox-group>

          <el-button
              size="mini"
              @click="calMoney()"
          >查询</el-button>

          <el-table
              :data="pmManager.cardCalDetail"
          >
            <el-table-column
                label="卡类"
                prop="name"
            />
            <el-table-column
                label="总数"
                prop="count"
            />
          </el-table>
        </el-collapse-item>
        <el-collapse-item title="查激活" name="3">
          <div style="text-align: left">
            <el-button style="width: 180px;margin-left: 10px" @click="batchQueryCardsInfo">批量查询</el-button>
          </div>

          <div style="text-align: left">
            <el-input
                style="width: 600px;margin-left: 250px;margin-top: -30px"
                v-model="pmManager.batchQueryCards"
                :rows="15"
                type="textarea"
            />
          </div>
          <div style="text-align: left">
            <el-button style="width: 180px;margin-left: 10px;margin-top: 30px" @click="queryCardsInfo">精准查询</el-button>
          </div>

          <div style="text-align: left">
            <el-input
                style="width: 600px;height:50px;margin-left: 250px;margin-top: -40px"
                v-model="pmManager.queryCard"
            />
          </div>
          <div style="text-align: left;margin-left: 30px">
            <el-table
                :data="pmManager.queryCardResult"
            >
              <el-table-column
                  label="卡号"
                  prop="card"
              >
              </el-table-column>
              <el-table-column
                  label="状态"
                  prop="status"
              >
              </el-table-column>
              <el-table-column
                  label="使用者"
                  prop="account"
              >
              </el-table-column>
              <el-table-column
                  label="创建日期"
                  prop="createtime"
              >
              </el-table-column>
              <el-table-column
                  label="使用日期"
                  prop="usetime"
              >
              </el-table-column>
              <el-table-column
                  label="结束日期"
                  prop="endtime"
              >
              </el-table-column>
            </el-table>
          </div>

        </el-collapse-item>
      </el-collapse>





    </el-tab-pane>

    <el-tab-pane label="U设置" name="ten">
      <el-form
          style="max-width: 600px"
          status-icon
          label-width="auto"
      >
        <el-form-item label="收款地址">
          <el-input v-model="udstManager.config.appKey" />
        </el-form-item>
        <el-form-item label="额外汇率">
          <el-input v-model="udstManager.config.rate" />
        </el-form-item>
        <el-form-item label="pid">
          <el-input v-model="udstManager.config.pid" />
        </el-form-item>
        <el-form-item label="paykey">
          <el-input v-model="udstManager.config.paykey" />
        </el-form-item>
        <el-form-item label="url">
          <el-input v-model="udstManager.config.url" />
        </el-form-item>

        <el-form-item>
          <el-button type="primary" style="margin-left: 100px;width: 200px" @click="updateUdstConfig()">
            保存
          </el-button>
        </el-form-item>
      </el-form>
    </el-tab-pane>


    <el-tab-pane label="组设置" name="eleven">
      <el-form
          style="max-width: 600px"
          status-icon
          label-width="auto"
      >
        <el-form-item label="组">
          <el-input v-model="groupManager.allGroups" />
        </el-form-item>
        <el-form-item>
          <el-button type="primary" style="margin-left: 100px;width: 200px" @click="updateAllGroup()">
            保存
          </el-button>
        </el-form-item>
      </el-form>
    </el-tab-pane>



  </el-tabs>
</template>

<script>

import { Search } from '@element-plus/icons-vue'
import { ElMessage, ElMessageBox } from 'element-plus'

export default {
  name: "AdminBoard",
  data() {
    return {
      Search:Search,
      nfManager:{
        nfCardType:[
          {
            value: 20,
            label: 'Apex',
          },
          {
            value: 23,
            label: '逃离塔科夫',
          },
          {
            value: 32,
            label: 'CS2',
          },
          {
            value: 33,
            label: 'CS2雷达版',
          },
          {
            value: 36,
            label: 'COD',
          },
          {
            value: 38,
            label: 'PUBG',
          },
        ]

        ,
        nfCardCreateInfo:{

        },
        nfCardCreateWindowShow:false
      },
      groupManager:{
        allGroups:""
      },
      udstManager:{
        config:{
          appkey:"",
          rate:0,
          pid:"",
          url:"",
          paykey:""
        }
      },
      userManager:{
        currentPage:1,
        statusDefine:[],
        searchCondition:{show:false},
        insertCondition:{show:false},
        insertUser:{
          username:"anna",
          model1Time:0,
          model2Time:0
        }
      },
      orderManager:{
        orders:[],
        currentPage:1,
        searchCondition:{show:false}
      },
      reasonManager:{
        currentPage:1,
        reasons:[],
      },
      uiManager:{
        advColor:'',
        advText:'',
        backgroundUrl:''
      },
      commonManager:{
        number:0,
        makeResult:"",
        running:false
      },
      pmManager:{
        batchQueryCards:"",
        queryCard:"",
        queryCardResult:[],
        cardActiveShowBoard:'4',
        cardCalDetailShow:false,
        cardCalDetail:[],
        createPrivatePriceInfo:{},
        pms:[],
        cards:[],
        queryCardInfoConditionRequest:{
          type:"",
          status:"",
          page:1,
          pageSize:50
        },
        cardHistory:[],
        cardPrivatePrice:[],
        cardHistoryShow:false,
        cardPrivatePriceShow:false,
        insertCondition:{show:false},
        calCardTypes:[],
        calBeginTime:0,
        calEndTime:0,
        insertUser:{
          account:"",
          password:""
        },
        createCardPoint:0,
        cardTypes:[],
        advText:"",
        createInfo:{
          type:"",
          cards:""
        }
      },
      activeName: 'first'
    };
  },
  watch:{

    activeName(){
      if(this.activeName == 'first'){
        this.queryAllUserInfo();
      }
      if(this.activeName == 'third'){
        this.queryAllOrderInfo();
      }
      if(this.activeName == 'six'){
        this.queryAllReason()
      }
      if(this.activeName == 'fourth'){
        this.getUIConfig()
      }
      if(this.activeName == 'seven'){
        this.flushAllGroup();
        this.initPmConfig()
      }
      if(this.activeName == 'eight'){
        this.flushAllGroup();
        this.flushCardInfo()
        this.queryAllPm()
      }
      if(this.activeName == 'night'){
        this.flushCardInfo();
      }
      if(this.activeName == 'ten'){
        this.flushUdstConfig();
      }
      if(this.activeName == 'eleven'){
        this.flushAllGroup();
      }
    }
  },
  methods: {

    getCardTitleById(id){
      for(let j = 0;j < this.pmManager.cardTypes.length;j++){
        if(this.pmManager.cardTypes[j].type == id){
          return this.pmManager.cardTypes[j].title;
        }
      }
      return id;

    },
    calMoney(){
      this.$axios.get('/admin/calMoney',{
        params: {
          "beginTime": this.pmManager.calBeginTime,
          "endTime": this.pmManager.calEndTime,
          "calCardTypes": this.pmManager.calCardTypes + ""
        }
      }).then(res=>{
        if(res.data.code == 1){
          this.pmManager.cardCalDetail = []
          res.data.data.details.forEach(e =>{
            this.pmManager.cardCalDetail.push({"name":e.name,"count":e.count});
          });
          console.log(this.pmManager.cardCalDetail);
          this.$message.success("" + res.data.data.sum);
        }
      });
    },
    createNfCard(){
      this.$axios.get('/admin/createNfCard',{params:this.nfManager.nfCardCreateInfo}).then(res=>{
        if(res.data.code == 1){
          let result = "";
          for(let i = 0;i < res.data.data.length;i++){
            result = result + res.data.data[i] + "\n";
          }
          this.commonManager.makeResult = result;
          this.nfManager.nfCardCreateWindowShow = false;
          this.$message.success("ok!");
        }
      });
    },
    batchQueryCardsInfo(){
      this.$axios.get('/admin/batchQueryCardActivated',{params:{'cards':this.pmManager.batchQueryCards}}).then(res=>{
        if(res.data.code == 1){
          let result = "";
          for(let i = 0;i < res.data.data.length;i++){
            result = result + res.data.data[i].card + " " + res.data.data[i].status + "\n";
          }
          this.pmManager.batchQueryCards = result;
          this.$message.success("ok!");
        }
      });
    },
    queryCardsInfo(){
      this.$axios.get('/admin/batchQueryCardActivated',{params:{'cards':this.pmManager.queryCard}}).then(res=>{
        if(res.data.code == 1){
          this.pmManager.queryCardResult = res.data.data
          this.$message.success("ok!");
        }
      });
    },
    batchInsertCard(cards){
      this.$axios.get('/admin/batchInsertCard',{params:{"type":this.pmManager.createInfo.type,"cards":cards}}).then(res=>{
        if(res.data.code == 1){
          this.$message.success("ok!");
          this.initPmConfig();
        }
      });
    },
    insertCardType(){
      ElMessageBox.prompt('请输入价格', 'NF', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
      })
          .then(({ value }) => {
            this.pmManager.createCardPoint = value;

            ElMessageBox.prompt('请输入卡类', 'NF', {
              confirmButtonText: '确定',
              cancelButtonText: '取消',
            })
                .then(({ value }) => {
                  this.$axios.get( '/admin/insertCardType',{params:{title:value,point:this.pmManager.createCardPoint}}).then(res => {
                    this.$message.success(res.data.msg);
                    this.initPmConfig();
                  }).catch(()=>{
                    this.$message.error("未知错误!");
                  });
                })
                .catch(() => {
                  ElMessage({
                    type: 'info',
                    message: '已取消创建操作',
                  })
                })
          })
          .catch(() => {
            ElMessage({
              type: 'info',
              message: '已取消创建操作',
            })
          })
    },
    flushUdstConfig(){
      this.$axios.get('/admin/getUdstConfig').then(res=>{
        this.udstManager.config = res.data
      });
    },
    updateUdstConfig(){
      this.$axios.get('/admin/updateUdstConfig',{params:this.udstManager.config}).then(res=>{
        if(res.data.code == 1){
          this.$message.success("ok!");
        }
      });
    },

    updateAllGroup(){
      this.$axios.get('/admin/updateAllGroup',{params:{"group":this.groupManager.allGroups}}).then(res=>{
        if(res.data.code == 1){
          this.$message.success("ok!");
        }
      });
    },
    getGroupArray(){
      return this.groupManager.allGroups.split(",");
    },
    flushAllGroup(){
      this.$axios.get('/admin/getAllGroup').then(res=>{
        this.groupManager.allGroups = res.data
      });
    },

    flushCardInfo(){
      this.$axios.get('/pm/getAllCardTypes').then(res=>{
        let arr = res.data;
        this.pmManager.cardTypes = [];
        if(arr.length < 1){
          return;
        }
        for(let i = 0; i < arr.length;i++){
          let e = {};
          e.type = arr[i].id;
          e.title = arr[i].title;
          e.number = arr[i].number;
          e.parent = arr[i].parent;
          e.point = arr[i].point;
          e.upoint = arr[i].upoint;
          e.organize = arr[i].organize;
          e.level = arr[i].level;
          this.pmManager.cardTypes.push(e);
        }
      });
      this.$axios.get('/admin/queryCardInfo',{params:this.pmManager.queryCardInfoConditionRequest}).then(res=>{
        this.pmManager.cards = res.data.data;
      });

    },
    deleteCard(id){
      this.$axios.get('/admin/deleteCard',{params:{"id":id}}).then(res=>{
        if(res.data.code == 1){
          this.$message.success("ok!");
          this.flushCardInfo();
        }
      });
    },
    deleteBoss(id){
      this.$axios.get('/admin/deleteBoss',{params:{"id":id}}).then(res=>{
        if(res.data.code == 1){
          this.$message.success("ok!");
          this.flushCardInfo();
        }
      });
    },
    deleteWjmPrivatePrice(id){
      this.$axios.get('/admin/deleteWjmPrivatePrice',{params:{"id":id}}).then(res=>{
        if(res.data.code == 1){
          this.$message.success("ok!");
          this.flushCardInfo();
        }
      });
    },
    deleteCardType(id){
      this.$axios.get('/admin/deleteCardType',{params:{"id":id}}).then(res=>{
        if(res.data.code == 1){
          this.$message.success("ok!");
          this.initPmConfig();
        }
      });
    },
    queryAllPm(){
      this.$axios.get( '/admin/getAllPm').then(res => {
        let code = res.data.code
        if(code == 1){
          this.pmManager.pms = res.data.data
          for(let i = 0; i < this.pmManager.pms.length;i++){
            this.pmManager.pms[i].organizesArray = this.pmManager.pms[i].organizes.split(',');
          }
        }else{
          this.$message.error(res.data.msg);
        }
      }).catch(()=>{

        this.$message.error("未知错误!");
      });
    },

    initPmConfig(){
      this.$axios.get('/global/getUiConfig').then(res=>{
        if(res.data.code == 1){
          let arr = res.data.data;
          for(let i = 0; i < arr.length;i++){
            let e = arr[i];
            switch (e.type){
              case 2:
                this.pmManager.advText = e.text;
                break;
            }
          }
        }
      });
      this.$axios.get('/pm/getAllCardTypes').then(res=>{
        let arr = res.data;
        this.pmManager.cardTypes = [];
        for(let i = 0; i < arr.length;i++){
          let e = {};
          e.type = arr[i].id;
          e.title = arr[i].title;
          e.number = arr[i].number;
          e.point = arr[i].point;
          e.parent = arr[i].parent;
          e.upoint = arr[i].upoint;
          e.organize = arr[i].organize;
          e.level = arr[i].level;
          this.pmManager.cardTypes.push(e);
        }
      })
    },
    savePmAdv(adv){
      this.$axios.get('/admin/savePmAdv',{params:{"adv":adv}}).then(res => {
        let code = res.data.code
        if(code == 1){
          this.$message.success(res.data.msg);
        }else{
          this.$message.error(res.data.msg);
        }
      }).catch(()=>{
        this.$message.error("未知错误!");
      });
    },
    queryUserInfoByConditon(){
      let cond = this.userManager.searchCondition;
      this.$axios.get( '/admin/queryUserInfo',{params:{"username":cond.username}}).then(res => {
        let code = res.data.code
        if(code == 1){
          this.userManager.users = res.data.data
          this.$message.success(res.data.msg);
        }else{
          this.$message.error(res.data.msg);
        }
      }).catch(()=>{
        this.$message.error("未知错误!");
      });
    },
    queryOrderInfoByConditon(){
      let cond = this.orderManager.searchCondition;
      this.$axios.get('/admin/queryOrderInfo',{params:{"no":cond.no,"username":cond.username}}).then(res => {
        let code = res.data.code
        if(code == 1){
          this.orderManager.orders = res.data.data
          this.$message.success(res.data.msg);
        }else{
          this.$message.error(res.data.msg);
        }
      }).catch(()=>{
        this.$message.error("未知错误!");
      });
    },
    insertPm(){
      this.$axios.get( '/admin/createPm',{params:this.pmManager.insertUser}).then(res => {
        let code = res.data.code
        if(code == 1){
          this.pmManager.pms = res.data.data
        }else{
          this.$message.error(res.data.msg);
        }
      }).catch(()=>{

        this.$message.error("未知错误!");
      });

    },
    insertUser(){
      this.$axios.get( '/admin/insertUser',{params:this.userManager.insertUser}).then(res => {
        let code = res.data.code
        if(code == 1){
          this.userManager.users = res.data.data
        }else{
          this.$message.error(res.data.msg);
        }
      }).catch(()=>{

        this.$message.error("未知错误!");
      });
    },
    queryAllUserInfo(){
      this.$axios.get( '/admin/queryUserInfo',{params:{page:this.userManager.currentPage}}).then(res => {
        let code = res.data.code
        if(code == 1){
          this.userManager.users = res.data.data
        }else{
          this.$message.error(res.data.msg);
        }
      },{
        withCredentials: true // 允许跨域请求发送Cookie
      }).catch(()=>{

        this.$message.error("未知错误!");
      });
    },
    queryAllOrderInfo(){
      this.$axios.get('/admin/queryOrderInfo',{params:{page:this.orderManager.currentPage}}).then(res => {
        let code = res.data.code
        if(code == 1){
          this.orderManager.orders = res.data.data
        }else{
          this.$message.error(res.data.msg);
        }
      }).catch(()=>{
        this.$message.error("未知错误!");
      });
    },
    queryAllReason(){
      this.$axios.get('/admin/reason',{params:{page:this.reasonManager.currentPage}}).then(res => {
        let code = res.data.code
        if(code == 1){
          this.reasonManager.reasons = res.data.data
        }else{
          this.$message.error(res.data.msg);
        }
      }).catch(()=>{

        this.$message.error("未知错误!");
      });
    },
    ban(obj){
      obj.status = -1;
      this.saveUser(obj)

    },
    resetHwnd(username){
      this.$axios.get('/admin/resetHwnd',{params:{"username":username}}).then(res => {
        let code = res.data.code
        if(code == 1){
          this.$message.success(res.data.msg);
        }else{
          this.$message.error(res.data.msg);
        }
      }).catch(()=>{
        this.$message.error("未知错误!");
      });
    },
    quickResetHwnd(){
      ElMessageBox.prompt('请输入要解绑的账号', 'NF', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
      })
          .then(({ value }) => {
            this.resetHwnd(value)
          })
          .catch(() => {
            ElMessage({
              type: 'info',
              message: '已取消解绑操作',
            })
          })
    },
    makeCard(){
      ElMessageBox.prompt('请输入要生成的数量', 'NF', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
      })
          .then(({ value }) => {
            this.commonManager.running = true;
            this.$axios.get( '/admin/makeNfCard',{params:{number:value}}).then(res => {
              let code = res.data.code
              if(code == 1){
                let result = "";
                for(let i = 0;i < res.data.data.length;i++){
                  result = result + res.data.data[i] + "\n";
                }
                this.commonManager.makeResult = result;
                this.$message.success(res.data.msg);
              }else{
                this.$message.error(res.data.msg);
              }
              this.commonManager.running = false;
            }).catch(()=>{
              this.commonManager.running = false;
              this.$message.error("未知错误!");
            });
          })
          .catch(() => {
            ElMessage({
              type: 'info',
              message: '已取消生成操作',
            })
          })
    },
    resetBan(obj){
      obj.status = 0;
      this.saveUser(obj)

    },
    saveCardTypeInfo(obj){
      this.$axios.get( '/admin/saveCardTypeInfo',{params:obj}).then(res => {
        let code = res.data.code
        if(code == 1){
          this.$message.success(res.data.msg);
        }else{
          this.$message.error(res.data.msg);
        }
      }).catch(()=>{

        this.$message.error("未知错误!");
      });

    },
    savePm(obj){

      obj.organize = ''
      for(let i =0;i < obj.organizesArray.length;i++){
        obj.organize = obj.organize + obj.organizesArray[i]
        if(i + 1 < obj.organizesArray.length){
          obj.organize = obj.organize + ','
        }
      }
      this.$axios.get( '/admin/savePmInfo',{params:{'account':obj.account,'point':obj.point,'upoint':obj.upoint,'needMoney':obj.needMoney,'organizes':obj.organize}}).then(res => {
        let code = res.data.code
        if(code == 1){
          this.$message.success(res.data.msg);
        }else{
          this.$message.error(res.data.msg);
        }
      }).catch(()=>{

        this.$message.error("未知错误!");
      });

    },
    updateWjmPrivatePrice(obj){
      this.$axios.get( '/admin/updateWjmPrivatePrice',{params:obj}).then(res => {
        let code = res.data.code
        if(code == 1){
          this.getWjmPrivatePriceByUser(obj.user)
          this.$message.success(res.data.msg);
        }else{
          this.$message.error(res.data.msg);
        }
      }).catch(()=>{

        this.$message.error("未知错误!");
      });
    },
    queryCardHistoryBeforeTwoMonth(account){
      this.$axios.get( '/admin/queryCardHistoryBeforeTwoMonth',{params: {"account":account}}).then(res => {
        let code = res.data.code
        if(code == 1){
          this.pmManager.cardHistory = res.data.data;
          this.pmManager.cardHistoryShow = true;
          this.$message.success(res.data.msg);
        }else{
          this.$message.error(res.data.msg);
        }
      }).catch(()=>{

        this.$message.error("未知错误!");
      });
    },
    getWjmPrivatePriceByUser(user){
      this.pmManager.createPrivatePriceInfo.user = user;

      this.$axios.get( '/admin/getWjmPrivatePriceByUser',{params: {"user":user}}).then(res => {
        let code = res.data.code
        if(code == 1){
          this.pmManager.cardPrivatePrice = res.data.data;
          this.pmManager.cardPrivatePriceShow = true;
          this.$message.success(res.data.msg);
        }else{
          this.$message.error(res.data.msg);
        }
      }).catch(()=>{

        this.$message.error("未知错误!");
      });
    },
    saveUser(obj){
      this.$axios.get( '/admin/saveUserInfo',{params:obj}).then(res => {
        let code = res.data.code
        if(code == 1){
          this.$message.success(res.data.msg);
        }else{
          this.$message.error(res.data.msg);
        }
      }).catch(()=>{

        this.$message.error("未知错误!");
      });
    },
    saveOrder(obj){
      this.$axios.get('/admin/saveOrderInfo',{params:obj}).then(res => {
        let code = res.data.code
        if(code == 1){
          this.$message.success(res.data.msg);
        }else{
          this.$message.error(res.data.msg);
        }
      }).catch(()=>{

        this.$message.error("未知错误!");
      });

    },
    getUIConfig(){
      this.$axios.get('/global/getUiConfig').then(res=>{
        if(res.data.code == 1){
          let arr = res.data.data;
          for(let i = 0; i < arr.length;i++){
            let e = arr[i];
            switch (e.type){
              case 0:
                this.uiManager.backgroundUrl = e.url;
                break;
              case 1:
                this.uiManager.advText = e.text;
                this.uiManager.advColor = e.color;
                break;
            }
          }
        }
        console.log(res)
      })
    },
    handleUserCurrentChange(number){
      this.userManager.currentPage = number;
      this.queryAllUserInfo();
    },
    handleOrderCurrentChange(number){
      this.orderManager.currentPage = number;
      this.queryAllOrderInfo();
    },
    handleCardCurrengChange(number){
      this.pmManager.queryCardInfoConditionRequest.page = number;
      this.flushCardInfo();
    },
    handleReasonCurrentChange(number){
      this.reasonManager.currentPage = number;
      this.queryAllReason();
    },
    saveUI(obj){
      this.$axios.get('/admin/saveUI',{params:obj}).then(res => {
        let code = res.data.code
        if(code == 1){
          this.$message.success(res.data.msg);
        }else{
          this.$message.error(res.data.msg);
        }
      }).catch(()=>{
        this.$message.error("未知错误!");
      });
    },

  },


  mounted() {
    this.userManager.statusDefine[-1] = "已封禁"
    this.userManager.statusDefine[0] = "正常"
    this.queryAllUserInfo();
  }
}
</script>

<style scoped>

</style>