<template>
  <div class="login-container">
    <h1>Login</h1>
    <form @submit.prevent="login">
      <div>
        <label for="username">Username:</label>
        <input type="text" id="username" v-model="loginForm.username" required>
      </div>
      <div>
        <label for="password">Password:</label>
        <input type="password" id="password" v-model="loginForm.password" required>
      </div>
      <button type="submit">Login</button>
    </form>
  </div>
</template>

<script>
import {ElMessage} from "element-plus";

export default {
  name: 'LoginPage',
  data() {
    return {
      loginForm: {
        username: '',
        password: ''
      }
    };
  },
  methods: {
   setCookie(name, value, days) {
  let expires = '';
  if (days) {
    const date = new Date();
    date.setTime(date.getTime() + (days * 24 * 60 * 60 * 1000));
    expires = '; expires=' + date.toUTCString();
  }
  document.cookie = name + '=' + (value || '') + expires + '; path=/';
},
    login() {
      const formData = new FormData();
      formData.append('username', this.loginForm.username);
      formData.append('password', this.loginForm.password);

      this.$axios.post('/admin/login', formData, {
        withCredentials: true // 允许跨域请求发送Cookie
      }) // 直接传递loginForm对象
          .then(res => {
            if (res.data.code === 1) {
              this.$axios.get( '/admin/queryOrderInfo').then(res => {
                let code = res.data.code
                console.log(code)
              },{
                withCredentials: true // 允许跨域请求发送Cookie
              }).catch(()=>{

              });
              ElMessage({
                type: 'success',
                message: res.data.msg,
              });
            } else {
              ElMessage({
                type: 'error',
                message: res.data.msg,
              });
            }
          })
          .catch(error => {
            // 在这里你可以访问到错误对象
            console.error('登录失败:', error);
            this.$message.error("登录失败!");
          });
      // 这里应该是登录逻辑，例如验证用户名和密码然后发送请求到服务器
      // 登录成功后跳转到主页或其他页面
    }
  }
};
</script>

<style scoped>
.login-container {
  width: 300px;
  margin: 100px auto;
  padding: 20px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
}
form div {
  margin: 10px 0;
}
label {
  display: block;
  margin-bottom: 5px;
}
input[type="text"], input[type="password"] {
  width: 100%;
  padding: 8px;
  margin-top: 2px;
}
button[type="submit"] {
  width: 100%;
  padding: 10px;
  margin-top: 10px;
  cursor: pointer;
}
</style>