<template>
  <div>
    <div  v-if="totalTime > 0">
      <h1 style="color: red">请在{{ totalTime }}秒内支付</h1>
      <h1  style="color: red">{{this.orderInfo.upoint}} USDT</h1>
      <h1 style="color: dodgerblue">不要多付也不要少付,否则无法到账</h1>
      <img src="usdt.png"/>
      <h2  style="color: black">USDT-TRC20收款地址 {{this.appKey}}</h2>
      <h1 style="color: red">支付期间请勿关闭网页!</h1>
    </div>
    <p v-else>订单已超时，请重新下单</p>
  </div>
</template>

<script>
export default {
  name: "UdstPage.vue",
  data() {
    return {
      appKey:"n",
      // 假设倒计时总时间为60秒
      totalTime: 0,
      orderInfo:{},
      extraMoney:0
    };
  },
  mounted() {
    this.extraMoney = this.$route.query.extraMoney
    this.getUdstOrder();
    this.getAppkey();
    // 开始倒计时
  },
  methods: {
    tryPayUdst(){
      this.$axios.get('/pm/tryPayUdst').then(res=>{
        if (res.data.code === 1 ) {
          alert(res.data.msg);
          window.location.href = 'https://nfhwid.cn';
        }
      })
    },
    getAppkey(){
      this.$axios.get('/pm/getAppkey').then(res=>{
        this.appKey = res.data

      })
    },
    getUdstOrder(){
      this.$axios.get('/pm/getUdstOrder',{params:{extraMoney:this.extraMoney}}).then(res=>{
         if(res.data.code === 2 || res.data.code === -1){
           alert(res.data.msg);
         }
         if (res.data.code === 1 || res.data.code === 2) {
           this.orderInfo = JSON.parse(res.data.data);
           this.totalTime = (1000 * 60 * 10 - new Date().getTime() + this.orderInfo.createTime) / 1000
           this.startCountdown();
         }else{
           window.location.href = 'https://nfhwid.cn';
         }
      })
    },
    // 倒计时方法
    startCountdown() {
      setTimeout(() => {
        const timestamp = new Date().getTime()
          if(timestamp - this.orderInfo.createTime  > 1000 * 60 * 10){
            window.location.href = 'https://nfhwid.cn';
          }else{
            this.totalTime = (1000 * 60 * 10 - new Date().getTime() + this.orderInfo.createTime) / 1000
            this.tryPayUdst();
            this.startCountdown();
          }

      }, 900);
    }
  }
}
</script>

<style scoped>

</style>