import AdminBoard from "@/components/AdminBoard";
import {createRouter, createWebHistory} from "vue-router";
import HelloWorld from "@/components/HelloWorld";
import LoginPage from "@/components/LoginPage";
import PmPage from "@/components/PmPage";
import NfBoard from "@/components/NfBoard";
import UdstPage from "@/components/UdstPage";

const routes = [
    {
        path:'/',
        component: HelloWorld,
    },
    {
        path:'/nibunengzuzhiwowansusu',
        component: AdminBoard,
    },
    {
        path:'/login',
        component: LoginPage,
    },
    {
        path:'/pm',
        component: PmPage,
    },
    {
        path:'/udst',
        component: UdstPage,
    },
    {
        path:'/nf',
        component: NfBoard,
    },
]
//定义路由器
const router = createRouter({
    routes,
    history: createWebHistory()
})

//导出路由器
export default router;