<template >
  <div :style="backGroudConfig" >


      <el-card style="position: fixed;left: 23%;border-radius: 20px;background-color: rgba(255, 255, 255, 0.8);top: 35%;width:50%;height: 40%" shadow="always">
        <div style="margin-top: 2%;">
          <span style="font-size: 20px;font-weight: bold;text-decoration: none;">卡网买卡每日两次解绑机会,残卡每月一次解绑机会</span>
          <a href="https://dmacheat.vip/" style="font-size: 20px;font-weight: bold;text-decoration: none;color: red">(点我跳转卡网)</a>
        </div>

        <div style="margin-top: 4%;">
          <el-text style="font-weight: bold;" size="large" type="primary">订单号: </el-text><el-input v-model="no" style="background-color: rgba(255, 255, 255, 0.8);width: 56%;" placeholder="请输入你的订单号" />
        </div>
        <div style="margin-top: 2%;">
          <el-text  size="large" type="primary" style="font-weight: bold;margin-left: 15px">账号: </el-text><el-input v-model="username" style="background-color: rgba(255, 255, 255, 0.8);width: 56%;" placeholder="请输入你的账号" />
        </div>
        <div style="margin-top: 2%;">
          <el-text  size="large" type="primary" style="font-weight: bold;margin-left: -15px">解绑原因: </el-text><el-input v-model="reason" style="background-color: rgba(255, 255, 255, 0.8);width: 56%;" placeholder="请输入你的解绑原因" />
        </div>
        <div style="margin-top: 2%">
          <el-button :disabled = "resetting" style="font-weight: bold;width: 35%" plain type="info" round @click="reset">解绑</el-button>
        </div>
      </el-card>

    <div class="advertisement">
      <el-card  style="background-color: rgba(255, 255, 255, 0.6);width: 350px">
        <template #header>
          <div style="font-weight: bold">公告</div>
        </template>
        <div v-if="adv.text != null && adv.text.length > 0">
          <p :key="i" v-for="(item,i) in adv.text.split('\n')" type="warning" :style="advConfig">
            {{item}}
          </p>

        </div>
      </el-card>
    </div>
  </div>
</template>

<script>
import { ElMessage, ElMessageBox } from 'element-plus'

export default {
  name: 'HelloWorld',
  data(){
    return{
      backgroundUrl:"",
      adv: {
        text: "绿色游戏健康生活，坚决抵制破坏国内游戏环境！！请勿交流任何关于国内平台的游戏！\n" +
            "\n" +
            "软件仅供学习交流使用，请勿用于国内平台，软件请在24小时内自行删除，否则后果自负！\n" +
            "\n" +
            "购买卡密后请尽快使用，本站出售的均为未使用卡密，请妥善保管您的卡密！！\n" +
            "\n" +
            "售后 解绑 疑难杂症等问题带订单联系客服即可，不带订单不回！不带订单不回！\n" +
            "\n" +
            "摁F严禁一人拥有多个账户 包括但不限于HWID相同 账号id相似等等\n" +
            "\n" +
            "如您将账号出售 出租 或注册多个账号导致封禁 不在我们售后范围内",
        color: "#f9963b"
      },
      username:"",
      no:"",
      reason:"",
      resetting: false
    }
  },
  methods:{
    flushUI(){
      this.$axios.get('/global/getUiConfig').then(res=>{
        if(res.data.code == 1){
          let arr = res.data.data;
          for(let i = 0; i < arr.length;i++){
            let e = arr[i];
            switch (e.type){
              case 0:
                this.backgroundUrl = e.url;
                break;
              case 1:
                this.adv.text = e.text;
                this.adv.color = e.color;
                  break;
            }
          }
        }
        console.log(res)
      })
    },
    reset(){
      if(this.username.length == 0){
        this.$message.error("请输入要绑定的账号");
        return;
      }
      if(this.reason.length < 5 || this.reason.length > 100 ){
        this.$message.error("解绑原因请认真填写");
        return;
      }
      ElMessageBox.confirm(
          '确认解绑该账号吗?解绑后该订单自动绑定该账号(该订单将不能解绑其他账号)',
          '提示',
          {
            confirmButtonText: '确认',
            cancelButtonText: '取消',
            type: 'warning',
          }
      )
          .then(() => {
            this.resetting = true
            this.$axios.get('/global/newReset',{params: {username:this.username,no:this.no.trim(),reason:this.reason}}).then(res => {
              ElMessage({
                type: res.data.code == 1 ? 'success':'error',
                message: res.data.msg,
              })
              this.resetting = false;
            }).catch(()=>{
              this.$message.error("未知错误!");
            });
          })
          .catch(() => {
            ElMessage({
              type: 'info',
              message: '已取消解绑',
            })
          })
    }
  },
  mounted() {
    this.flushUI();
  },
  computed: {
    // 使用计算属性来生成样式对象
    backGroudConfig() {
      return {
        marginTop: '-63px',
        height: '100%',
        width: '100%',
        // 使用动态数据变量来设置背景图片 URL
        background: `url('${this.backgroundUrl}') fixed no-repeat`,
        backgroundSize: 'cover',
        position: 'fixed'
      };
    },
    advConfig(){
      return{
        fontSize: '14px',
        color: this.adv.color,
        textAlign: 'left',
        fontWeight:'bold',
        wordBreak: 'break-all'
      }
    }
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.advertisement {
  position: fixed;
  top: 10px;   /* 距离页面顶部的距离 */
  right: 10px; /* 距离页面右侧的距离 */
}
</style>
