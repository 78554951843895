<template>
    <h3>代理端---------------------严禁外传</h3>
    <h4 style="color: red">{{this.adv}}</h4>

  <div>
    <el-container>
      <el-aside width="500px">
        <div style="width: 500px" >
          <el-form-item  :label="'余额 ' + (this.payType == 0 ?this.point:this.upoint) + ' 元'"  >

          </el-form-item>

          <el-form-item  label="钱包" >
            <el-select v-model="payType" placeholder="请选择钱包">
              <el-option label="支付宝" :value="0" v-if="payTypes.includes('ZFB')"></el-option>
              <el-option label="USDT" :value="1" v-if="payTypes.includes('USDT')"></el-option>
            </el-select>
          </el-form-item>
          <el-form-item  label="卡类" >
            <el-select v-model="createInfo.cardFirstType" placeholder="请选择卡类">
              <el-option v-for="item in this.cardTypes" :key="item.id" :label="item.aliasTitle" :value="item.aliasTitle"></el-option>
            </el-select>
          </el-form-item>
          <el-form-item  label="天数" >
            <el-select v-model="createInfo.type" placeholder="请选择天数">

              <el-option v-for="item in this.cardTypesMap[this.createInfo.cardFirstType]" :key="item.day" :label="item.day" :value="item.type"></el-option>
            </el-select>
          </el-form-item>
          <div v-if="this.cardTypesMap[this.createInfo.cardFirstType]">
            <el-form-item v-if="payType == 0" :label="'价格     ' + this.getCurrentTypePoint() + ' 元'"  >
            </el-form-item>
            <el-form-item v-if="payType == 1" :label="'价格     ' +  this.getCurrentTypeUPoint() + ' 元'"  >
            </el-form-item>
            <el-form-item  :label="'剩余     ' + this.getCurrentNumber() + ' 张'"  >
            </el-form-item>
          </div>

          <el-form-item label="卡密" >
            <el-input
                v-model="card"
                :rows="10"
                type="textarea"
            />
          </el-form-item>

          <el-form-item label="充值更多金额" >
            <el-input
                v-model="addMoney"
            />
          </el-form-item>
          <el-form-item>
            <el-button v-if="payType == 0" style="width: 200px;margin-left: 45px" type="primary" @click="createCardByPm(createInfo.type)">生成卡密</el-button>
            <el-button  v-if="payType == 0" style="width: 200px;margin-left: 45px" type="primary" @click="pay()">充值</el-button>
            <el-button v-if="payType == 1" style="width: 200px;margin-left: 45px" type="primary" @click="createCardByPmSourceU(createInfo.type)">生成卡密</el-button>
            <el-button  v-if="payType == 1" style="width: 200px;margin-left: 45px" type="primary" @click="gotoudst()">充值</el-button>
          </el-form-item>
        </div>

      </el-aside>
      <el-container>
        <el-header>
          <el-table
              :data="cardHistory"
          >
            <el-table-column
                label="卡号"
                prop="card"
            >
            </el-table-column>
<!--            <el-table-column-->
<!--                label="注册码"-->
<!--                prop="registerCode"-->
<!--            >-->
<!--            </el-table-column>-->
            <el-table-column
                label="使用日期"
                prop="time"
            >
            </el-table-column>

          </el-table>
          <el-pagination
              style="margin-left: 300px"
              @current-change="handleHistoryCurrentChange"
              :page-size="100"
              :pager-count="10"
              layout="prev, pager, next"
              :total="10000"
          />


        </el-header>

      </el-container>
    </el-container>





  </div>
</template>

<script>
import {ElMessage, ElMessageBox} from "element-plus";

export default {
  name: "PmPage",
  data(){
    return{
      cardTypesMap:{},
      payTypes:"",
      cardHistory:[],
      currentPage:0,
      point:0,
      payurl:"https://x1.usero.cn/submit.php",
      upoint:0,
      adv:"请勿外传",
      cardTypes:[],
      card:"",
      addMoney:0,
      payType:1,

      createInfo:{
        type:""
      }
    }
  },


  methods:{
    handleHistoryCurrentChange(number){
      this.currentPage = number;
      this.flushCardHistory();
    },

    flushCardHistory(){
      this.$axios.get('/pm/getPmCreatedCard',{params:{type:this.createInfo.type,current:this.currentPage}}).then(res=>{
        this.cardHistory = res.data.data;
      });
    },
    gotoudst(){
      this.$router.push({ path: `/udst`, query: { extraMoney: this.addMoney }});
    },


    getCurrentTypePoint(){

      for(let i = 0;i < this.cardTypesMap[this.createInfo.cardFirstType].length;i++){
        if(this.cardTypesMap[this.createInfo.cardFirstType][i].type == this.createInfo.type){
          return this.cardTypesMap[this.createInfo.cardFirstType][i].point;
        }
      }
      return 0;
    },
    getCurrentTypeUPoint(){
      for(let i = 0;i < this.cardTypesMap[this.createInfo.cardFirstType].length;i++){
        if(this.cardTypesMap[this.createInfo.cardFirstType][i].type == this.createInfo.type){
          return this.cardTypesMap[this.createInfo.cardFirstType][i].upoint;
        }
      }
      return 0;
    },
    getCurrentNumber(){
      for(let i = 0;i < this.cardTypesMap[this.createInfo.cardFirstType].length;i++){
        if(this.cardTypesMap[this.createInfo.cardFirstType][i].type == this.createInfo.type){
          return this.cardTypesMap[this.createInfo.cardFirstType][i].number;
        }
      }
      return 0;
    },
    flushUI(){
      this.$axios.get('/global/getUiConfig').then(res=>{
        if(res.data.code == 1){
          let arr = res.data.data;
          for(let i = 0; i < arr.length;i++){
            let e = arr[i];
            switch (e.type){
              case 2:
                this.adv = e.text;
                break;
            }
          }
        }
      })
    },
    isStringEmpty(str) {
      return typeof str === 'undefined' || str === null || str.trim().length === 0;
    },
    pay(){
      this.$axios.get( '/pay/getPaySign',{params:{extraMoney:this.addMoney,return_url:"http://nfhwid.cn/pm"}}).then(res => {
        let ps1 = res.data
        if(ps1 == 'none'){
          this.$message.error("当前没有权限使用支付宝支付!");
        }else{
          window.location.replace(this.payurl + "?" + ps1)
        }

      }).catch(()=>{
        this.$message.error("未知错误!");
      });
    },
    createCardByPm(type){
      ElMessageBox.prompt('请输入要生成的数量', 'NF', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
      })
          .then(({ value }) => {
            this.$axios.get( '/pm/createCardByPm',{params:{type:type,number:value}}).then(res => {
              let code = res.data.code
              if(code == 1){
                let arr = JSON.parse(res.data.data)
                let result = "";
                for(let i = 0;i < arr.length;i++){
                  result = result + arr[i].card + "\n";
                }
/*                result = result + "==============================注册码==============================\n";*/
                for(let i = 0;i < arr.length;i++){
                  result = result + arr[i].registerCode + "\n";
                }
                this.card = result;
                this.$message.success(res.data.msg);
                this.updatePoint();
              }else{
                this.$message.error(res.data.msg);
              }
            }).catch(()=>{
              this.$message.error("未知错误!");
            });
          })
          .catch(() => {
            ElMessage({
              type: 'info',
              message: '已取消生成操作',
            })
          })
    },
    createCardByPmSourceU(type){
      ElMessageBox.prompt('请输入要生成的数量', 'NF', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
      })
          .then(({ value }) => {
            this.$axios.get( '/pm/createCardByPmSourceU',{params:{type:type,number:value}}).then(res => {
              let code = res.data.code
              if(code == 1){
                let arr = JSON.parse(res.data.data)
                let result = "";
                for(let i = 0;i < arr.length;i++){
                  result = result + arr[i].card + "\n";
                }
                // result = result + "==============================注册码==============================\n";
                // for(let i = 0;i < arr.length;i++){
                //   result = result + arr[i].registerCode + "\n";
                // }
                this.card = result;
                this.$message.success(res.data.msg);
                this.updatePoint();
              }else{
                this.$message.error(res.data.msg);
              }
            }).catch(()=>{
              this.$message.error("未知错误!");
            });
          })
          .catch(() => {
            ElMessage({
              type: 'info',
              message: '已取消生成操作',
            })
          })
    },
    getCardTypes(){
      this.$axios.get('/pm/getCardTypes').then(res=>{
          let arr = res.data;
          this.cardTypes = [];
          this.cardTypesMap = {};
          for(let i = 0; i < arr.length;i++){
            let e = {};
            e.type = arr[i].id;
            e.title = arr[i].title;
            e.point = arr[i].point;
            e.upoint = arr[i].upoint;
            e.number = arr[i].number;
            if(arr[i].parent != null){
              const parentParam = arr[i].parent.split("|");
                e.day = parentParam[0];
                e.aliasTitle = parentParam[1];
                if(this.isStringEmpty(e.aliasTitle)){
                  e.aliasTitle = e.title;
                }
                if(this.isStringEmpty(e.day)){
                  e.day = "未定义";
                }
            }else{
              e.aliasTitle = e.title;
              e.day = "未定义";
            }

            if(!this.cardTypesMap[e.aliasTitle]){
              this.cardTypes.push(e);
              this.cardTypesMap[e.aliasTitle] = []
            }
            this.cardTypesMap[e.aliasTitle].push({day:e.day,type:e.type,point:e.point,upoint:e.upoint,number:e.number})



          }

      })
    },

    updatePoint(){
      this.$axios.get('/pm/getPoint').then(res=>{
        this.point = res.data;
      })


      this.$axios.get('/pm/getPayUrl').then(res=>{
        this.payurl = res.data;
      })
      this.$axios.get('/pm/getUPoint').then(res=>{
        this.upoint = res.data;
      })
      this.$axios.get('/pay/getPayType').then(res=>{
        this.payTypes = res.data;
      })
    }
  },

  mounted() {
    this.updatePoint();
    this.flushUI();
    this.getCardTypes();
    setInterval(this.updatePoint, 4000);
  },
  watch:{

    'createInfo.type':function (newValue){
      console.log(newValue);
      this.flushCardHistory();
    },
    'createInfo.cardFirstType':function (newValue){
      console.log(newValue);
      this.createInfo.type = null;
    }
  }
}
</script>

<style scoped>

</style>